<template>
  <div>
    <img
      src="../../assets/404.png"
      alt="404"
      description="コンテンツがありません"
    />
    <p class="mainContext">指定されたページは存在しません</p>
    <p class="subContext">URLが正しく入力されているか、確認してください</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  components: {},
};
</script>

<style>
dev {
  style: 'padding: 1em;';
}
img {
  width: 80%;
  margin: 0 auto;
  padding-left: 25%;
}
.mainContext {
  font-size: 3vmax;
  color: black;
  font-family: 'Mishafi Gold';
  width: 80%;
  margin: 0 auto;
  padding-top: 3%;
  text-align: center;
}
.subContext {
  font-size: 1.5vmax;
  color: #9197a3;
  font-family: 'Mishafi Gold';
  width: 80%;
  margin: 0 auto;
  padding-top: 2%;
  text-align: center;
}
</style>
